<template>
  <div>
    <form @submit.prevent="submitHandler" v-form-enter v-if="!isLoading">
      <div class="mb-3 profile-container" align="center">
        <ProgressSpinner
          v-if="spinnerLoading"
          style="width: 40px; height: 124px"
          animationDuration=".75s"
          strokeWidth="4"
          class="z-index:5"
          id="spinner"
        />
        <template v-else>
          <img
            v-if="user.profile_picture"
            :src="user.profile_picture"
            class="profile-preview"
          />

          <i v-else class="la la-user no-profile" />
        </template>

        <Button
          @click="uploadProfile"
          icon=" las la-pen   i-20"
          class="p-button-lg p-button-rounded grey-button floating-profile-edit"
        />
      </div>
      <file-pond
        style="display: none"
        name="profile"
        ref="pond"
        label-idle="Upload Profile picture"
        :allow-multiple="false"
        accepted-file-types="image/jpeg, image/png"
        :files="myFiles"
        @init="handleFilePondInit"
        @processfile="handleProcessFile"
        @processfilestart="processfilestart"
      />
      <div class="col-12 formgrid grid flex justify-content-center">
        <div class="field col-12 sm:col-12">
          <label class="label">Name</label>
          <div class="p-inputgroup">
            <InputText placeholder="First Name" v-model="user.name" />
          </div>
        </div>
        <div class="field col-12 sm:col-12">
          <label class="label">Surname</label>

          <div class="p-inputgroup">
            <InputText placeholder="Surname" v-model="user.surname" />
          </div>
        </div>
        <div class="field col-12 sm:col-12">
          <label class="label">Username</label>
          <div class="p-inputgroup">
            <InputText placeholder="Username" v-model="user.username" />
          </div>
        </div>

        <div class="field col-12 sm:col-12">
          <label class="label"><i class="las la-lock"></i>Email</label>

          <div class="p-inputgroup">
            <InputText
              disabled
              placeholder="Email address"
              v-model="user.email"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-12 pb-0">
          <label class="label"><i class="las la-lock"></i>Password</label>

          <div class="p-inputgroup">
            <Password
              disabled
              v-model="password"
              :placeHolder="password"
              :feedback="false"
            />
          </div>
          <div class="pt-2 pr-1" align="right">
            <a class="password-reset cursor-pointer" @click="resetPassword()"
              >Change Password</a
            >
          </div>
        </div>
        <div class="field col-12 sm:col-12">
          <label class="label">Contact Number</label>
          <div class="p-inputgroup">
            <InputText
              placeholder="Phone number"
              v-model="user.contact_number"
            />
          </div>
        </div>
        <div class="field col-12 sm:col-12">
          <label class="label">Birthdate</label>
          <div class="p-inputgroup">
            <Calendar
              autocomplete="off"
              placeholder="Date of birth"
              v-model="user.birthdate"
              dateFormat="yy-mm-dd"
              :yearNavigator="true"
              yearRange="1940:2020"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-content-between pt-5 col-12">
        <Button type="submit"> UPDATE </Button>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>
    <RocketLoad :full="true" v-else />
    <Dialog
      contentStyle="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px"
      :visible.sync="display"
      :modal="true"
      :closable="true"
      ><PasswordResetPopup @dismiss="display = false"></PasswordResetPopup
    ></Dialog>
  </div>
</template>
<script>
import store from "@/store";
import vueFilePond, { setOptions } from "vue-filepond";
import Dialog from "primevue/dialog";
import PasswordResetPopup from "@/application/Settings/components/PasswordResetPopup";
setOptions({
  server: {
    url: process.env.VUE_APP_UPLOAD_URL,
    timeout: 7000,
    process: {
      url: "/v1/users/upload",
      method: "POST",
      headers: {
        Authorization: "Bearer " + store.getters.token,
      },
    },
  },
});

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
//import FilePondPluginImagePreview from "filepond-plugin-image-preview";

// Create component
const FilePond = vueFilePond(
  FilePondPluginFileValidateType
  //FilePondPluginImagePreview
);

export default {
  name: "UserSliderDetails",
  components: {
    FilePond,
    Dialog,
    PasswordResetPopup,
  },
  data() {
    return {
      display: false,
      spinnerLoading: false,
      myFiles: [],
      name: "",
      email: "",
      password: "********",
      primary_role: null,
      secondary_role: null,
      profilePicture: "",
      user: null,
      isLoading: true,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.getUser();
  },
  methods: {
    resetPassword() {
      this.display = true;
    },
    uploadProfile() {
      this.$refs.pond.browse();
    },
    handleProcessFile: function (error, file) {
      const response = JSON.parse(file.serverId);
      this.profilePicture = response.data;
      this.user.profile_picture = this.profilePicture.url;
      this.spinnerLoading = false;
    },
    handleFilePondInit: function () {
      this.$refs.pond.getFiles();
    },
    processfilestart() {
      this.spinnerLoading = true;
    },
    getUser() {
      this.$axios
        .get(process.env.VUE_APP_ROOT_API + "/v1/users/" + this.user_uid, {})
        .then((response) => {
          this.user = response.data.data;

          if (!this.user.working_hours) {
            this.user.working_hours = {
              mon: { on: true, hours: "06:00", label: "Monday" },
              tue: { on: true, hours: "06:00", label: "Tuesday" },
              wed: { on: true, hours: "06:00", label: "Wednesday" },
              thu: { on: true, hours: "06:00", label: "Thursday" },
              fri: { on: true, hours: "06:00", label: "Friday" },
              sat: { on: false, hours: "00:00", label: "Saturday" },
              sun: { on: false, hours: "00:00", label: "Sunday" },
            };
          }
          // this.primary_role = this.user.primary_role.uid;
          // this.secondary_role = this.user.secondary_role.uid;
          if (this.user.email) {
            this.user.email = this.user.email.email;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    submitHandler() {
      this.user.primary_role = this.primary_role;
      this.user.secondary_role = this.secondary_role;
      this.user.birthdate = this.$moment(this.user.birthdate).format(
        "YYYY-MM-DD"
      );
      this.isLoading = true;
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/users/" + this.user_uid,
          this.user,
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.$store.dispatch("setProfilePicture", this.user.profile_picture);
          this.clearSliderContent();
          this.$toast.add({
            severity: "success",
            summary: "User Saved",
            detail: this.user.name + " has been saved",
            life: 3000,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
    saveUserStatus() {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/users/status/" + this.user_uid,
          this.user,
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.isLoading = false;
          this.$toast.add({
            severity: "success",
            summary: "User Status Updated",
            life: 3000,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
  },
  computed: {
    user_uid() {
      return this.$store.getters.selectedObject;
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
.profile-preview {
  height: 124px;
  width: 124px;
  border-radius: 100%;
  object-fit: cover;
}

.no-profile {
  font-size: 124px;
  background-color: #b9c5d0;
  color: #f2f4f6;
  border-radius: 100%;
}
/* error state color */
[data-filepond-item-state*="error"] .filepond--item-panel,
[data-filepond-item-state*="invalid"] .filepond--item-panel {
  background-color: #fe4d97;
}

[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  background-color: #6dd230;
}

.floating-profile-edit {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 110px;
  visibility: hidden;
}

.profile-container:hover .floating-profile-edit {
  visibility: unset;
}

.password-reset {
  color: #778ca2;
}

.password-reset:hover {
  color: #4d7cfe;
}
</style>