<template>
  <form style="width: 400px" @submit.prevent="submitHandler" v-form-enter>
    <template v-if="!isLoading">
      <div>
        <div class="formgrid grid col-12 pt-0">
          <div class="field col-12 sm:col-12">
            <label class="label">New Password</label>
            <div class="p-inputgroup">
              <Password
                toggleMask
                :feedback="true"
                v-model="resetPassword.password1"
                placeholder="Enter your new password"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.resetPassword.password1.required"
            >
              New password is required
            </div>
          </div>
          <div class="field col-12 sm:col-12">
            <label class="label">Confirm New Password</label>
            <div class="p-inputgroup">
              <Password
                toggleMask
                :feedback="true"
                v-model="resetPassword.password2"
                placeholder="Re-enter your new password"
              />
            </div>
            <div
              class="error-validation-text pt-1"
              v-if="submitted && !$v.resetPassword.password2.required"
            >
              Confirm your new password
            </div>
          </div>
        </div>
        <div class="flex col-12 justify-content-between pt-1 pb-0">
          <div>
            <Button type="submit"> Reset </Button>
          </div>
          <Button class="p-button-text p-button-secondary" @click="dismiss">
            CANCEL
          </Button>
        </div>
      </div>
    </template>
    <div
      style="width: 400px; max-width: 90vw"
      v-else
      class="flex col-12 align-items-center justify-content-between"
      v-for="u in 3"
      :key="u"
    >
      <Skeleton class="col" height="50px" borderRadius="6px" />
    </div>
  </form>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      isLoading: false,
      resetPassword: {
        password1: null,
        password2: null,
      },
    };
  },
  validations: {
    resetPassword: {
      password1: {
        required,
      },
      password2: {
        required,
      },
    },
  },
  computed: {
    user_uid() {
      return this.$store.getters.selectedObject;
    },
  },
  mounted() {},
  methods: {
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        if (this.resetPassword.password1 !== this.resetPassword.password2) {
          this.$toast.add({
            severity: "error",
            summary: "Your passwords no dont match",
            life: 3000,
          });
        } else {
          this.isLoading = true;
          this.$axios
            .post(
              process.env.VUE_APP_ROOT_API +
                "/v1/users/reset/password/" +
                this.user_uid,
              JSON.stringify(this.resetPassword),
              {
                headers: { "Content-type": "application/json" },
              }
            )
            .then((response) => {
              this.isLoading = false;
              this.clearSliderContent();
              this.$store.dispatch("logout");
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response && error.response.status === 401) {
                console.error("NOOOOOOOOO!");
              } else {
                // Handle error however you want
              }
              console.error(error);
            });
        }
      }
    },
    dismiss() {
      this.$emit("dismiss");
    },
  },
};
</script>

<style  scoped>
</style>